<template>
  <div>
    <div class="div-form">
      <Messages />
      <div class="div-title">
        <span class="title-login">Login de acesso</span>
        <br />
      </div>
      <b-row>
        <b-col sm="12">
          <InputText title="Usuário" field="userName" :formName="formName" :required="true" :maxLength="50"
            v-model="login.userName">
            <div class="glyphicon">
              <i class="fas fa-user"></i>
            </div>
          </InputText>
          <InputPassword title="Senha" field="password" :formName="formName" :required="true" :maxLength="50"
            v-model="login.password">
            <div class="glyphicon">
              <i class="fas fa-lock"></i>
            </div>
          </InputPassword>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="6">
          <Button _key="sigin" type="primary" title="Entrar" :disabled="!isFormValid(formName)"
            classIcon="fad fa-sign-out-alt" size="medium" :clicked="sigin" />
        </b-col>
        <b-col class="text-right" md="6">
          <div class="div-forgot-password">Esqueceu a senha? </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Messages from "@nixweb/nixloc-ui/src/component/shared/Messages.vue";

import Login from "@/components/modules/adm/login/Login.js";
import Menu from "@/components/modules/adm/login/Menu.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Login",
  components: { InputText, Button, InputPassword, Messages },
  data() {
    return {
      formName: "login",
      login: new Login(),
      menu: new Menu(),
      urlLogin: "api/v1/adm/auth/login",
    };
  },
  created() {
    this.addUserLogged({});
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("user", ["addUserLogged", "addItemMenu", "removeItemMenu"]),
    sigin() {
      let params = { url: this.urlLogin, obj: this.login };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.addUserLogged(response.content);
          this.loadMenu();
          this.$router.replace(this.$route.query.redirect || "/dashboard");
        }
        this.removeLoading(["sigin"]);
      });
    },
    loadMenu() {
      this.removeItemMenu();
      let self = this;
      this.userLogged.user.rules.forEach(function (rule) {
        self.hasRule(rule);
      });
    },
    hasRule(rule) {
      let self = this;
      this.menu.itens.forEach(function (item) {
        if (rule.name === item.rule) self.addItemMenu([item]);
      });
    },
  },
};
</script>
<style scoped>
.div-form {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.div-title {
  margin-bottom: 30px;
}

.title-login {
  font-size: 25px;
}

.div-forgot-password {
  margin-top: 5px;
  cursor: pointer;
  color: #4680a5;
}

.title-rodape {
  font-size: 13px;
}
</style>
