export default class Menu {
    constructor() {
        this.itens = [
            {
                order: 0,
                isModule: true,
                rule: "Adm",
                module: "Adm",
                title: "Admin...",
                isVisible: "all",
                icon: "fa-light fa-screwdriver-wrench",
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "group",
                isVisible: "all",
                groupName: "Principal",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Adm",
                module: "Adm",
                type: "link",
                title: "Usuário",
                isVisible: "all",
                routeName: "userList",
                icon: "fas fa-users"
            },
            {
                order: 1,
                isModule: true,
                rule: "Billing",
                module: "Billing",
                isVisible: "all",
                title: "Cobran..",
                icon: "fa-regular fa-money-bill-trend-up",
            },
            {
                isModule: false,
                rule: "Billing",
                module: "Billing",
                type: "group",
                isVisible: "all",
                groupName: "Cobrança",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Billing",
                module: "Billing",
                type: "link",
                title: "Boleto",
                isVisible: "all",
                routeName: "billetList",
                icon: "fa-solid fa-barcode",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Billing",
                module: "Billing",
                type: "link",
                title: "Cobrança",
                isVisible: "all",
                routeName: "chargingList",
                icon: "fa-regular fa-money-bill-transfer",
                iconColor: "#577696"
            },
            {
                order: 2,
                isModule: true,
                rule: "CRM",
                module: "CRM",
                isVisible: "all",
                title: "CRM",
                icon: "fa-sharp fa-light fa-handshake",
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "group",
                isVisible: "all",
                groupName: "CRM",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "link",
                title: "Lead",
                isVisible: "all",
                routeName: "leadList",
                icon: "fa-sharp fa-solid fa-people-arrows",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "link",
                title: "Cliente",
                isVisible: "all",
                routeName: "customerList",
                icon: "fas fa-user",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "group",
                isVisible: "all",
                groupName: "Outros",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "CRM",
                module: "CRM",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "configCrm",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                order: 3,
                isModule: true,
                rule: "Planner",
                module: "Planner",
                isVisible: "all",
                title: "Planner",
                icon: "fa-light fa-chart-kanban",
            },
            {
                isModule: false,
                rule: "Planner",
                module: "Planner",
                type: "group",
                isVisible: "all",
                groupName: "Planner",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Planner",
                module: "Planner",
                type: "link",
                title: "Quadro",
                isVisible: "all",
                routeName: "frameList",
                icon: "fa-solid fa-clone",
                iconColor: "#577696"
            },
            {
                isModule: false,
                rule: "Planner",
                module: "Planner",
                type: "group",
                isVisible: "all",
                groupName: "Outros",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Planner",
                module: "Planner",
                type: "link",
                title: "Configuração",
                isVisible: "all",
                routeName: "plannerConfig",
                icon: "fas fa-cog",
                iconColor: "#528DD7"
            },
            {
                order: 4,
                isModule: true,
                rule: "Support",
                module: "Support",
                title: "Suporte",
                isVisible: "all",
                icon: "fa-light fa-headset",
            },
            {
                isModule: false,
                rule: "Support",
                module: "Support",
                type: "group",
                isVisible: "all",
                groupName: "Principal",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Support",
                module: "Support",
                type: "link",
                title: "Empresa",
                isVisible: "all",
                routeName: "supportCompanyList",
                icon: "fas fa-users"
            },
            {
                isModule: false,
                rule: "Support",
                module: "Support",
                type: "group",
                isVisible: "all",
                groupName: "Comunicação",
                icon: "fas fa-user-lock",
            },
            {
                isModule: false,
                rule: "Support",
                module: "Support",
                type: "link",
                title: "Aviso",
                isVisible: "all",
                routeName: "comunicationCreateUpdate",
                icon: "fa-solid fa-tower-broadcast"
            },
            {
                isModule: false,
                rule: "Support",
                module: "Support",
                type: "link",
                title: "Treinamento",
                isVisible: "all",
                routeName: "courseList",
                icon: "fa-solid fa-graduation-cap"
            },
        ]
    }
}
